import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import Brands from "../components/base/Brands";
import WhyChooseUs from "../components/base/WhyChooseUs";
import Testimonial from "../components/base/Testimonial"
import CustomFooter from "../components/CustomFooter";

export const WebStaticAnalysisPageTemplate = ({
  seo,
  hero,
  contactus,
  wasasection,
  wcfisection,
  sastsection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} titlePostfix="%s" article_section={seo.article_section} blogservice={true} />
      <HeroOther data={hero} />
      <section className="section custom-section">
        <div className="container" style={{ textAlign: 'center' }}>
          <SubTitle title={contactus.title} style={{ fontSize: '1.2rem' }} />
          <div style={{ marginTop: '1rem' }}>
            <a href={contactus.buttonlink} target="_blank" rel="noopener noreferrer">
              <button className="btn-light">{contactus.buttontxt} &#10230;</button>
            </a>
          </div>
        </div>
      </section >
      <section className="section custom-section" style={{ background: `${wasasection.sectionbg}` }}>
        <div className="container">
          <Descriptions descriptions={wasasection.descriptions} color={wasasection.textcolor} margin="0 0 1rem" />
          <div>
            <a href="/contact" target="_blank" rel="noopener noreferrer">
              <button className="btn-light">Get Your Applications Tested Today &#10230;</button>
            </a>
          </div>
        </div >
      </section >
      <section className="section wcfisection custom-section" style={{ background: `${wcfisection.sectionbg}`, position: 'relative' }}>
        <div className="container">
          <Title title={wcfisection.title} color={wcfisection.textcolor} />
          <Title title={wcfisection.secondtitle} color={wcfisection.textcolor} />
          <SubTitle title={wcfisection.subheader} color={wcfisection.textcolor} margin="0 0 1.5rem" />
          <Descriptions descriptions={wcfisection.descriptions} color={wcfisection.textcolor} margin="0 0 1rem" />
          <div style={{ color: '#fff' }}>
            <p style={{ fontWeight: '500' }}>Dynamic testing doesn’t provide insights into:</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
              {[
                'Whether the cryptography is secure.',
                'How passwords are stored.',
                'How system passwords are stored.',
                'What server side calls are being made and if they are secure.'
              ].map((el, i) => (
                <li key={i} style={{ margin: '10px 0' }}>{el}</li>
              ))}
            </ul>
            {[
              'It could take a dynamic PEN tester hours or days to bypass a specific security controls. But ,by looking at the code, you can easily identify its weaknesses.',
              'Want to review the code using static analysis (SAST)? Then, you’re in the right place.',
              'We are security experts, and we understand code.',
            ].map((el, i) => <p style={{ marginBottom: '1rem' }} key={i}>{el}</p>)}
          </div>
          <div>
            <a href="/contact" target="_blank" rel="noopener noreferrer">
              <button>Call Us &#10230;</button>
            </a>
          </div>
          <div className="hero-image" style={{ width: 200, height: 100, position: 'absolute', bottom: -20, right: 0 }}>
            <PreviewCompatibleImage imageInfo={wasasection.image} />
          </div>
        </div >
      </section >
      <section className="section custom-section" style={{ background: `${wasasection.sectionbg}` }}>
        <div className="container">
          <Title title={sastsection.title} color={wasasection.textcolor} />
          <Title title={sastsection.secondtitle} color={wasasection.textcolor} />
          <SubTitle title={sastsection.subheader} color="#fff" margin="0 0 1.5rem" />
          <Descriptions descriptions={sastsection.descriptions} color={wasasection.textcolor} margin="0 0 1rem" />
          <div>
            <a href="/contact" target="_blank" rel="noopener noreferrer">
              <button className="btn-light">Have Us Test Your Applications Today &#10230;</button>
            </a>
          </div>
        </div >
      </section >
      <Brands />
      <WhyChooseUs
        title="We can conduct static analysis to thoroughly review the code of your web applications for security issues. With our specialized penetration testing services, you can rest assured that your apps are secure."
        list={[{
          title: 'We have years of coding experience.',
          para: 'We know how to develop secure code, and we regularly assess applications.'
        }, {
          title: 'We are all developers and we understand applications.',
          para: 'We aren’t only experts in security, we also know how applications are (and SHOULD be) built. This knowledge helps us thoroughly test your applications with static analysis (SAST).',
        }, {
          title: 'We are security experts who train others.',
          para: 'Our application security specialists regularly instruct for large corporations and global security training companies. We teach people how to design and develop secure code.'
        }]} />
      <Testimonial />
      <CustomFooter data={{
        title: 'Want to Develop Secure Code for Your Applications?',
        subtitle: '',
        para: [
          'Have our security engineers review your code through static analysis security testing (SAST). We can help you identify security vulnerabilities and give you advice about how to fix them.',
          'We’ll help you ensure that your applications are secure.'
        ],
        linkTitle: 'Talk to Our Experts Now'
      }} />
    </div >
  )

WebStaticAnalysisPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  wasasection: PropTypes.object,
  wcfisection: PropTypes.object,
  sastsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const WebStaticAnalysisPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <WebStaticAnalysisPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        wasasection={frontmatter.wasasection}
        wcfisection={frontmatter.wcfisection}
        sastsection={frontmatter.sastsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

WebStaticAnalysisPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default WebStaticAnalysisPage

export const pageQuery = graphql`
  query WebStaticAnalysisPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "web-static-analysis" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        wasasection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        wcfisection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          requires {
            title
            description
          }
        }
        sastsection {
          title
          secondtitle
          subheader
          descriptions
          buttontxt
          buttonlink
          buttoncolor
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
